import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    user: null,
    isLoggedIn: false,
    loading: false,
    loadingCreate: false,
    loadingUpdate: false,
  },
  getters: {
    user: state => state.user,
    loading: state => state.loading,
    loadingCreate: state => state.loadingCreate,
    loadingUpdate: state => state.loadingUpdate,
    isLoggedIn: state => state.isLoggedIn,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_LOADING_CREATE(state, loadingCreate) {
      state.loadingCreate = loadingCreate
    },
    SET_LOADING_UPDATE(state, loadingUpdate) {
      state.loadingUpdate = loadingUpdate
    },
    SET_LOGGED_IN(state, flag) {
      state.isLoggedIn = flag
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('search-and-bookmark-data')
      document.location.reload()
    },
    showToastError(context, payload) {
      if (payload.status !== 424) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: `Ошибка ${payload.status}`,
            icon: 'InfoIcon',
            variant: 'danger',
            text: payload.message,
          },
        })
      } else {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Внимание',
            icon: 'InfoIcon',
            variant: 'warning',
            text: payload.message,
          },
        })
      }
    },
    async showSwalError(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        icon: 'error',
        html: payload.message,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    async fetchServiceData(context, payload) {
      return axios.get(`cabinet/service-data?stype=${payload.stype}`)
    },
    async fetchData(context, payload) {
      return axios.get(payload)
    },
    async sendData(context, payload) {
      const url = payload.apiId ? `${payload.api}/${payload.apiId}` : payload.api
      return axios.post(url, payload.data)
    },
    async buyerWork(context, payload) {
      return axios.put(`buyer/work?id_buyer=${payload.id_buyer}&stype=${payload.stype}&x1param=${payload.x1param}&query=${payload.query}`).then(res => {
        const { data } = res

        if (data.success) {
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Оповещение',
              icon: 'InfoIcon',
              variant: 'success',
              text: data.data,
            },
          })
        }

        return res
      })
    },
    async uploadFile(context, payload) {
      const formData = new FormData()
      formData.append('file', payload.file)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(e) {
          if (e.lengthComputable) {
            // eslint-disable-next-line prefer-template, no-console
            console.log(Math.round((e.loaded / e.total) * 100) + '%')
          }
        },
      }

      const res = await axios.post(
        'upload/file',
        formData,
        config,
      )

      const { data } = res

      return data
    },
  },
}
