import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://api.hoglabest.com/api/v1/',
})

axiosIns.interceptors.request.use(config => {
  if (config.params) {
    const params = new URLSearchParams(JSON.parse(JSON.stringify(config.params))).toString()
    if (config.url.indexOf('?') > -1) {
      // eslint-disable-next-line no-param-reassign
      config.url += `&${params}`
    } else {
      // eslint-disable-next-line no-param-reassign
      config.url += `?${params}`
    }
  }

  // eslint-disable-next-line no-param-reassign
  delete config.params

  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
